import React, { useEffect } from 'react'
import solidaridad from '../assets/solidaridad.webp';
import responsabilidad from '../assets/responsabilidad.webp';
import respeto from '../assets/respeto.webp';
import libertad from '../assets/libertad.webp';
import tituloValores from '../assets/titulos/nuestros-valores.webp';
import textos from '../Data/textos.json';
import Aos from 'aos'

const Valores = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="container-fluid pt-5 parallax-container-valores">
            <div className="container">
                <div className="text-center pb-2" >
                    {/*   <h1 className="mb-4 text-tomate-outline">Nuestros valores</h1> */}
                    <img src={tituloValores} className='titulos' alt="Nuestras profes" data-aos="fade-down"/>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3 text-center mb-5" data-aos="fade-up">
                        <div className="position-relative overflow-hidden mb-4" >
                            <img className="img-fluid w-75" src={libertad} alt="Libertad" />
                        </div>
                        <div className='bg-valor' >
                            <h5>{textos.valores.valorUno.nombre}</h5>
                            <p>{textos.valores.valorUno.descripcion}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-5" data-aos="fade-right">
                        <div className="position-relative overflow-hidden mb-4" >
                            <img className="img-fluid w-75" src={respeto} alt="Respeto" />
                        </div>
                        <div className='bg-valor' >
                        <h5>{textos.valores.valorDos.nombre}</h5>
                        <p>{textos.valores.valorDos.descripcion}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-5" data-aos="fade-up">
                        <div className="position-relative overflow-hidden mb-4" >
                            <img className="img-fluid w-75" src={responsabilidad} alt="Responsabilidad" />
                        </div>
                        <div className='bg-valor' >
                        <h5>{textos.valores.valorTres.nombre}</h5>
                        <p>{textos.valores.valorTres.descripcion}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center mb-5" data-aos="fade-right">
                        <div className="position-relative overflow-hidden mb-4" >
                            <img className="img-fluid w-75" src={solidaridad} alt="Solidaridad" />
                        </div>
                        <div className='bg-valor' >
                        <h5>{textos.valores.valorCuatro.nombre}</h5>
                        <p>{textos.valores.valorCuatro.descripcion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Valores