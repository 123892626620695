import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import WhatsApp from "./Components/WhatsApp";
import BlogUno from "Pages/Blogs/BlogUno";
import BlogTres from "Pages/Blogs/BlogTres";
import BlogDos from "Pages/Blogs/BlogDos";

/* https://ionic.io/ionicons */

function App() {


  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/galeria" element={<Gallery />} />
        <Route path="/contactanos" element={<Contact />} />

        <Route path="/blog_uno" element={<BlogUno />} />
        <Route path="/blog_dos" element={<BlogDos />} />
        <Route path="/blog_tres" element={<BlogTres />} />

        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>

      <WhatsApp />
      <Footer />

    </>
  );
}

export default App;
