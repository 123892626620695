import { useEffect, useState } from 'react';
import textos from '../Data/textos.json'
import Aos from 'aos'

const Indicadores = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    const [counts, setCounts] = useState({
        niños: 0,
        servicio: 0,
        academias: 0,
        abrazos: 0
    });

    const targetCounts = {
        niños: textos.indicadores.indicadorUno.valor,
        servicio: textos.indicadores.indicadorDos.valor,
        academias: textos.indicadores.indicadorTres.valor,
        abrazos: textos.indicadores.indicadorCuatro.valor
    };

    const [isActivated, setIsActivated] = useState(false);

    const handleScroll = () => {
        const section = document.getElementById('countdown');
        if (section) {
            const rect = section.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsActivated(isVisible);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isActivated) {
                setCounts((prevCounts) => ({
                    niños: Math.min(prevCounts.niños + 1, targetCounts.niños),
                    servicio: Math.min(prevCounts.servicio + 1, targetCounts.servicio),
                    academias: Math.min(prevCounts.academias + 1, targetCounts.academias),
                    abrazos: Math.min(prevCounts.abrazos + 1, targetCounts.abrazos)
                }));
            }
        }, 50);

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearInterval(interval);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isActivated, targetCounts.niños, targetCounts.servicio, targetCounts.academias, targetCounts.abrazos]);


    return (
        <>
            <div id="countdown" className={`bg-indicadores text-center py-5 ${isActivated ? 'activated' : ''}`}>
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul className="countdown">
                            <li>
                                <span className='text-indicador-outline'>{counts.niños}</span>
                                <h5 className='filter-shadow'>{textos.indicadores.indicadorUno.nombre}</h5>
                            </li>
                            <li>
                                <span className='text-indicador-outline'>{counts.servicio}</span>
                                <h5>{textos.indicadores.indicadorDos.nombre}</h5>
                            </li>
                            <li>
                                <span className='text-indicador-outline'>{counts.academias}</span>
                                <h5>{textos.indicadores.indicadorTres.nombre}</h5>
                            </li>

                            <li>
                                <span className='text-indicador-outline'>∞</span>{/* count.abrazos */}
                                <h5>{textos.indicadores.indicadorCuatro.nombre}</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Indicadores;
