import React from 'react'

const CardDocentes = ({ nombres, profesion, descripcion, img, direccion }) => {


    return (
        <div className="row align-items-center" /* sx={{ marginBottom: "20px" }} */>
            {(direccion === "derecha") ?
                <div className="col-lg-4">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={img} alt=""></img>
                </div>
                : ""
            }
            <div className="col-lg-7">
                <h4 className="mb-4">{nombres}</h4>
                <p className="section-title pr-0">
                    <span className="pr-2">{profesion}</span>
                </p>
                <p>
                    {descripcion}
                </p>
            </div>
            {(direccion === "izquierda") ?
                <div className="col-lg-4">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={img} alt=""></img>
                </div>
                : ""
            }
        </div>
    )
}

export default CardDocentes