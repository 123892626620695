import HeaderBlog from 'Components/HeaderBlog'
import React, { useEffect } from 'react'
import textos from '../../Data/textos.json'
import CardRecomendaciones from 'Components/CardRecomendaciones'
import bolg1 from '../../assets/desarrollo-ninos-pequenos-blog-1.webp';
import bolg2 from '../../assets/compromiso-activo-blog-2.webp';
import tituloBolg from '../../assets/titulos/je-blogs.webp';

const BlogTres = () => {

    const scrollToSection = (inicio) => {
        window.location.hash = "#" + inicio;
    };

    useEffect(() => {
        scrollToSection("inicio")
    }, []);

    return (
        <>
            <HeaderBlog
                banner="banner-img-blog-tres"
                titulo={textos.seccionBlogs.blogTres.titulo}
            />
            <div className="container-fluid pt-5" id="niveles">
                <div className="container">
                    <div className="text-center pb-2">
                        <h1 className="mb-4 text-tomate-outline">{textos.seccionBlogs.blogTres.titulo}</h1>
                    </div>
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12">
                            <p className='texto-justificado'>
                                La creatividad es una fuerza motriz en el desarrollo infantil, y en este blog, exploraremos cómo cultivar y nutrir la chispa creativa en los niños. Descubre cómo la educación creativa no solo estimula la imaginación, sino que también sienta las bases para el pensamiento crítico y la resolución de problemas.
                            </p>
                            <strong className="text-green-oscuro">1. Ambiente Estimulante:</strong>
                            <p className='texto-justificado'>
                                Crear un entorno que inspire la creatividad es esencial. Proporciona materiales artísticos, libros, y espacios de juego que inviten a la exploración y expresión libre.
                            </p>
                            <strong className="text-green-oscuro">2. Fomentar la Curiosidad:</strong>
                            <p className='texto-justificado'>
                                La educación creativa comienza con la curiosidad. Anima a los niños a hacer preguntas, explorar ideas y buscar respuestas. La curiosidad es la semilla de la creatividad.
                            </p>
                            <strong className="text-green-oscuro">3. Aprender a Través del Juego:</strong>
                            <p className='texto-justificado'>
                                El juego no solo es divertido, sino también educativo. Los juegos creativos promueven el pensamiento lateral, la colaboración y el descubrimiento de soluciones innovadoras.
                            </p>
                            <strong className="text-green-oscuro">4. Proyectos Manuales:</strong>
                            <p className='texto-justificado'>
                                Las actividades manuales no solo son divertidas, sino que también desarrollan habilidades motoras finas y fomentan la creatividad. Experimentar con arte, manualidades y construcciones despierta la imaginación.
                            </p>
                            <strong className="text-green-oscuro">5. Fomentar la Divergencia:</strong>
                            <p className='texto-justificado'>
                                En lugar de buscar respuestas "correctas", alienta a los niños a explorar múltiples soluciones. El pensamiento divergente nutre la creatividad al abrir espacio para diferentes perspectivas y enfoques.
                            </p>
                            <strong className="text-green-oscuro">6. Integrar las Artes:</strong>
                            <p className='texto-justificado'>
                                La música, el teatro, la danza y las artes visuales son herramientas poderosas para expresar la creatividad. Integrar las artes en la educación amplía el espectro de experiencias creativas.
                            </p>
                            <strong className="text-green-oscuro">7. Celebrar el Fracaso como Oportunidad:</strong>
                            <p className='texto-justificado'>
                                La creatividad florece cuando se permite el error. En lugar de temer al fracaso, enséñales que es parte del proceso creativo y una oportunidad para aprender y mejorar.
                            </p>
                            <p className='texto-justificado'>
                                Fomentar la creatividad desde una edad temprana no solo enriquece la vida de los niños, sino que también sienta las bases para mentes flexibles y preparadas para el futuro. ¡Despierta la creatividad en tus pequeños y sé testigo de un mundo lleno de posibilidades!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="text-center pb-2">
                        <img src={tituloBolg} className='titulos' alt="JE Blogs" />
                        {/* <h1 className="mb-4 text-tomate-outline">Artículos</h1> */}
                    </div>
                    <div className="row pb-3 d-flex justify-content-center">
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogUno.titulo}
                            img={bolg1}
                            descripcion={textos.seccionBlogs.blogUno.descipcionCorta}
                            pathBlog='/blog_uno'
                        />
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogDos.titulo}
                            img={bolg2}
                            descripcion={textos.seccionBlogs.blogDos.descipcionCorta}
                            pathBlog='/blog_dos'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogTres
