import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FiMapPin } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import logoJE from '../assets/logos/je-logo-full-color.png';
import textos from '../Data/textos.json'
import Aos from 'aos'


const Footer = () => {

    const [añoActual, setAñoActual] = useState('');

    useEffect(() => {
        const getCurrentYear = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            setAñoActual(year);
        };

        getCurrentYear();

    }, []);

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="container-fluid bg-footer text-white mt-5 py-5 px-sm-3 px-md-5">
            <div className="row pt-5">
                <div className="col-lg-4 col-md-6 mt-n">
                    <a href="/" className="p-1">
                        <img className="img-fluid" src={logoJE} alt="" width={300}/>
                    </a>
                    <p className='text-footer'>{textos.datosJE.textoFooter}</p>
                    <div className="d-flex justify-content-start text-responsive mt-4">
                        <a className="mr-2" href={textos.datosJE.facebook} target="_blank" rel="noreferrer">
                            <ion-icon size="large" name="logo-facebook"></ion-icon></a>
                        <a className="mx-2" href={textos.datosJE.instagram} target="_blank" rel="noreferrer">
                            <ion-icon size="large" name="logo-instagram"></ion-icon></a>
                        <a className="mx-2" href={textos.datosJE.tiktok} target="_blank" rel="noreferrer">
                            <ion-icon size="large" name="logo-tiktok"></ion-icon></a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-n">
                    <h4 className="text-white mb-4 text-responsive" >Contáctanos</h4>
                    <div className="d-flex">
                        <FiMapPin size={20} />
                        <div className="pl-3">
                            <h5 className="text-white">Dirección</h5>
                            <p>{textos.datosJE.direccion}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <FiMail size={20} />
                        <div className="pl-3">
                            <h5 className="text-white">Email</h5>
                            <a className="text-white" href={`mailto:${textos.datosJE.email}`}>
                                <p>{textos.datosJE.email}</p>
                            </a>
                        </div>
                    </div>
                    <div className="d-flex">
                        <FiPhone size={20} />
                        <div className="pl-3">
                            <h5 className="text-white">Teléfono</h5>
                            <p>{textos.datosJE.telefono}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-5 mt-n">
                    <h4 className="text-primary mb-4 text-responsive">Accesos rápidos</h4>
                    <div className="d-flex flex-column justify-content-start">
                        <Link to="/" className="text-white mb-2"><ion-icon name="chevron-forward-outline"></ion-icon>Inicio</Link>
                        <Link to="/nosotros" className="text-white mb-2"><ion-icon name="chevron-forward-outline"></ion-icon>Conoce nuestro centro</Link>
                        <Link to="/servicios" className="text-white mb-2"><ion-icon name="chevron-forward-outline"></ion-icon>Nuestros servicios</Link>
                        <Link to="/galeria" className="text-white mb-2"><ion-icon name="chevron-forward-outline"></ion-icon>Galería de recuerdos</Link>
                        <a href={`https://api.whatsapp.com/send?phone=${textos.datosJE.whatsapp}`} target="_blank" rel="noreferrer" className="text-white mb-2"><ion-icon name="chevron-forward-outline"></ion-icon>Contáctanos</a>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-5">
                <p className="m-0 text-center text-white">
                    &copy;
                    <Link className="text-primary font-weight-bold" to="/">{añoActual} Jardín Esperanza</Link>
                    Todos los derechos reservados.
                </p>
            </div>
        </div>
    )
}

export default Footer