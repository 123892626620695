import AliceCarousel from 'react-alice-carousel'
import React, { useEffect } from 'react'
import CardDocentes from '../Components/CardDocentes'
import textos from '../Data/textos.json'
import tituloProfes from '../assets/titulos/profes.webp';
import profeyessi from '../assets/docentes/profe-jessy.png';
import profegaby from '../assets/docentes/profe-gaby.png';
import profekathy from '../assets/docentes/profe-kathy.png';
import profelucy from '../assets/docentes/profe-lucy.png';
import profemica from '../assets/docentes/profe-mica.png';
import Aos from 'aos'

const Docentes = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    const itemsDocentes = [
        <CardDocentes
            img={profeyessi}
            direccion="derecha"
            nombres={textos.docentes.docente1.nombre}
            profesion={textos.docentes.docente1.profesion}
            descripcion={textos.docentes.docente1.descripcion}
        />,
        <CardDocentes
            img={profegaby}
            direccion="izquierda"
            nombres={textos.docentes.docente2.nombre}
            profesion={textos.docentes.docente2.profesion}
            descripcion={textos.docentes.docente2.descripcion}
        />,
        <CardDocentes
            img={profekathy}
            direccion="derecha"
            nombres={textos.docentes.docente3.nombre}
            profesion={textos.docentes.docente3.profesion}
            descripcion={textos.docentes.docente3.descripcion}
        />,
        <CardDocentes
            img={profelucy}
            direccion="izquierda"
            nombres={textos.docentes.docente4.nombre}
            profesion={textos.docentes.docente4.profesion}
            descripcion={textos.docentes.docente4.descripcion}
        />,
        <CardDocentes
            img={profemica}
            direccion="derecha"
            nombres={textos.docentes.docente5.nombre}
            profesion={textos.docentes.docente5.profesion}
            descripcion={textos.docentes.docente5.descripcion}
        />
    ]

    return (
        <div className="container-fluid pt-5" id="docentes">
            <div className="container">
                <div className="text-center pb-2" >
                    <img src={tituloProfes} className='titulos' alt="Nuestras profes" data-aos="fade-down"/>
                    {/*   <h1 className="mb-4 text-tomate-outline">Nuestras profesionales</h1> */}
                </div>
                <AliceCarousel
                    autoHeight
                    autoPlay
                    autoPlayInterval={3000}
                    animationDuration={2000}
                    animationType="fadeleft"
                    infinite
                    touchTracking={false}
                    mouseTracking
                    /*  disableDotsControls */
                    disableButtonsControls
                    items={itemsDocentes}
                />
            </div>
        </div>
    )
}

export default Docentes