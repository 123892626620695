import { useEffect } from 'react';
import tituloTestimonios from '../assets/titulos/papitos.webp';
import Aos from 'aos'

const Testimonios = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);
    
    return (
        <>
            <div className="text-center testimonio-back">
                <img src={tituloTestimonios} className='padres mt-5' alt="Testimonios"  data-aos="fade-up"/>
            </div>
            <div className="container-fluid test-sobrepuesto py-5">
                <div className="container-md">
                    <div className='row'>
                        <div className='col-md-6 d-video' >
                            <video controls className='video-container borde color-borde-video' data-aos="fade-up">
                                <source src={require('../assets/testimonio/je-testimonio-1.mp4')} type="video/mp4" />
                            </video>
                        </div>
                        <div className='col-md-6 d-video' >
                            <video controls className='video-container borde color-borde-video' data-aos="fade-right">
                                <source src={require('../assets/testimonio/je-testimonio-2.mp4')} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Testimonios