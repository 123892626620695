import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
/* import { Link } from 'react-router-dom'; */
import tituloProfes from '../assets/titulos/profes.webp';
import profeyessi from '../assets/docentes/profe-jessy.png';
import profegaby from '../assets/docentes/profe-gaby.png';
import profekathy from '../assets/docentes/profe-kathy.png';
import profelucy from '../assets/docentes/profe-lucy.png';
import profemica from '../assets/docentes/profe-mica.png';
import textos from '../Data/textos.json'
import { useEffect } from 'react';
import Aos from 'aos'

const DocentesHome = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    const itemsDocentes = [
        <div className="col-lg-4 mb-2 item d-flex justify-content-center" data-value="1">
            <div className="card-body pb-2">
                <img className="mb-2 img-fluid-doc" src={profeyessi} alt="profeyessi" />
            </div>
        </div>,
        <div className="col-lg-4 mb-2 item d-flex justify-content-center" data-value="2">
            <div className="card-body pb-2">
                <img className="mb-2 img-fluid-doc" src={profegaby} alt="profegaby" />
            </div>
        </div>,
        <div className="col-lg-4 mb-2 item d-flex justify-content-center" data-value="3">
            <div className="card-body pb-2">
                <img className="mb-2 img-fluid-doc" src={profekathy} alt="profekathy" />
            </div>
        </div>,
        <div className="col-lg-4 mb-2 item d-flex justify-content-center" data-value="3">
            <div className="card-body pb-2">
                <img className="mb-2 img-fluid-doc" src={profelucy} alt="profelucy" />
            </div>
        </div>,
        <div className="col-lg-4 mb-2 item d-flex justify-content-center" data-value="3">
            <div className="card-body pb-2">
                <img className="mb-2 img-fluid-doc" src={profemica} alt="profemica"></img>
            </div>
        </div>
    ]

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return (
        <div className="border-0 container-fluid pt-5">
            <div className="container">
                <div className="text-center pb-2" >
                    <img src={tituloProfes} className='titulos' alt="Nuestras profes" data-aos="fade-up"/>
                    {/*   <h1 className="mb-4 text-tomate-outline">Nuestras profesionales</h1> */}
                </div>
                <div className="row mx-2">
                    <AliceCarousel
                        autoPlay
                        autoPlayInterval={1000}
                        animationDuration={2000}
                        animationType="fadeleft"
                        infinite
                        touchTracking={false}
                        mouseTracking
                        disableDotsControls
                        disableButtonsControls
                        items={itemsDocentes}
                        responsive={responsive}
                    />
                </div>
                <div className="card- text-center">
                    <p className="card-text"  data-aos="fade-right">
                        {textos.docentes.decripcionGeneral}
                    </p>
                </div>
               {/*  <div className="d-flex align-items-center justify-content-center mt-4 mb-5" >
                    <Link to="/nosotros" className="btn btn-secondary">Conoce más sobre nuestras profesionales</Link>
                </div> */}
            </div>
        </div>
    )
}

export default DocentesHome
