/* import Animacion from 'Components/Animacion'; */
import Aos from 'aos';
import { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import DocentesHome from 'scanes/DocentesHome';
import Indicadores from 'scanes/Indicadores';
import Slogan from 'scanes/Slogan';
import Niveles from '../scanes/Niveles';
import Recomendaciones from '../scanes/Recomendaciones';
import Testimonios from '../scanes/Testimonios';

const Home = () => {

    const scrollToSection = (inicio) => {
        window.location.hash = "#" + inicio;
    };

    useEffect(() => {
        Aos.init({ duration: 1500 });
        scrollToSection("inicio")
    }, []);

    return (
        <>
            <div id='inicio' className="container-fluid d-flex align-items-center justify-content-center bg-banner-home parallax-container-1 mb-5">
                <div className="col-lg-8 text-center">
                    <h1 className="display-2 mb-4 font-weight-bold text-white text-green-outline text-move-up-down" data-aos="fade-right">
                        Jardín Esperanza
                    </h1>
                    <Link to="/nosotros" data-aos="fade-right" className="btn btn-secondary mt-1 py-3 px-5">Conoce más aquí</Link>
                </div>
            </div>
            
            <Niveles />
            <Slogan />
            <DocentesHome />
            <Testimonios />
            <Indicadores />
            <Recomendaciones />

        </>
    )
}

export default Home