import React, { useEffect } from 'react'
import CardNivel from '../Components/CardNivel'
/* import AliceCarousel from 'react-alice-carousel' */
import textos from '../Data/textos.json'
import tituloNiveles from '../assets/titulos/niveles.webp';
import jeinicial from '../assets/niveles/je-inicial-poratada.png';
import jematernal from '../assets/niveles/je-maternal-poratada.png';
import jesalacuna from '../assets/niveles/je-sala-cuna-poratada.png';
import Aos from 'aos'

const Niveles = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    /*  const itemsCardsNivel = [
         <>
             <div className="row mx-2">
                 <CardNivel
                     img="salacuna.jpeg"
                     tittle="Sala Cuna"
                     description="¡Descubra la excelencia en cuidado infantil en nuestro centro educativo Jardín Esperanza! Ofrecemos una Sala Cuna de primera clase, diseñada especialmente..."
                     edad="3 - 12 meses"
                     horario="Jornada Completa"
                 />
                 <CardNivel
                     img="maternal1.jpeg"
                     tittle="Maternal 1"
                     description="En Jardín Esperanza, creemos en el poder de los comienzos. Nuestro Maternal I es el lugar donde los pequeños comienzan a explorar el mundo que les rodea..."
                     edad="1 - 2 Años"
                     horario="Jornada Completa"
                 />
                 <CardNivel
                     img="maternal2.jpeg"
                     tittle="Maternal 2"
                     description="En Jardín Esperanza, damos la bienvenida a la etapa de exploración y descubrimiento de su pequeño en nuestro Maternal II, aquí nos enfocamos en cultivar sus..."
                     edad="2 - 3 Años"
                     horario="Jornada Completa"
                 />
             </div>
         </>,
         <>
             <div className="row mx-2">
                 <CardNivel
                     img="inicial1.jpeg"
                     tittle="Inicial I"
                     description="En Jardín Esperanza, el nivel Inicial I es el inicio de un viaje emocionante hacia el crecimiento integral de su hijo. Aquí, nos enfocamos en estimular las áreas cruciales de su desarrollo..."
                     edad="3 - 4 Años"
                     horario="Jornada Completa"
                 />
                 <CardNivel
                     img="maternal1.jpeg"
                     tittle="Inicial 2"
                     description="En Jardín Esperanza, el Nivel Inicial II es mucho más que una etapa preescolar. Es el comienzo de un viaje emocionante hacia el desarrollo integral de su hijo. Aquí, valoramos la diversidad..."
                     edad="4 - 5 Años"
                     horario="Jornada Completa"
                 />
             </div>
         </>
     ] */

    return (
        <div className="border-0 container-fluid">
            <div className="container">
                <div className="text-center pb-2">
                    <img src={tituloNiveles} className='titulos' alt="niveles" data-aos="fade-up" />
                    {/*   <h1 className="mb-4 text-tomate-outline">Nuestros Niveles</h1> */}
                </div>

                {/*   <AliceCarousel
                    autoHeight
                    autoPlay
                    autoPlayInterval={3000}
                    animationDuration={2000}
                    animationType="fadeleft"
                    infinite
                    touchTracking={false}
                    mouseTracking
                    disableDotsControls
                    disableButtonsControls
                    controlsStrategy="alternate"
                    items={itemsCardsNivel}
                /> */}
                <div className="row mx-2">
                    <CardNivel
                        img={jesalacuna}
                        description={textos.niveles.salaCuna.descripcionCorta}
                    />
                    <CardNivel
                        img={jematernal}
                        description={textos.niveles.maternal.descripcionCorta}
                    />
                    <CardNivel
                        img={jeinicial}
                        description={textos.niveles.inicial.descripcionCorta}
                    />
                </div>
            </div>
        </div>
    )
}

export default Niveles


