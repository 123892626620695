import React, { useEffect } from 'react'
import CardRecomendaciones from '../Components/CardRecomendaciones'
import tituloBolg from '../assets/titulos/je-blogs.webp';
import bolg1 from '../assets/desarrollo-ninos-pequenos-blog-1.webp';
import bolg2 from '../assets/compromiso-activo-blog-2.webp';
import bolg3 from '../assets/aprendizaje-divertido-blog-3.webp';
import textos from '../Data/textos.json'
import Aos from 'aos'

const Recomendaciones = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="container-fluid pt-5">
            <div className="container">
                <div className="text-center pb-2" >
                    <img src={tituloBolg} className='titulos' alt="JE Blogs" data-aos="fade-right" />
                    {/* <h1 className="mb-4 text-tomate-outline">Artículos</h1> */}
                </div>
                <div className="row pb-3">

                    <CardRecomendaciones
                        titulo={textos.seccionBlogs.blogUno.titulo}
                        img={bolg1}
                        descripcion={textos.seccionBlogs.blogUno.descipcionCorta}
                        pathBlog='/blog_uno'
                        dataAos='fade-up-right'
                    />
                    <CardRecomendaciones
                        titulo={textos.seccionBlogs.blogDos.titulo}
                        img={bolg2}
                        descripcion={textos.seccionBlogs.blogDos.descipcionCorta}
                        pathBlog='/blog_dos'
                        dataAos='fade-up'
                    />
                    <CardRecomendaciones
                        titulo={textos.seccionBlogs.blogTres.titulo}
                        img={bolg3}
                        descripcion={textos.seccionBlogs.blogTres.descipcionCorta}
                        pathBlog='/blog_tres'
                        dataAos='fade-down'
                    />
                </div>
            </div>
        </div>
    )
}

export default Recomendaciones
