import HeaderBlog from 'Components/HeaderBlog'
import React, { useEffect } from 'react'
import textos from '../../Data/textos.json'
import CardRecomendaciones from 'Components/CardRecomendaciones'
import bolg1 from '../../assets/desarrollo-ninos-pequenos-blog-1.webp';
import bolg3 from '../../assets/aprendizaje-divertido-blog-3.webp';
import tituloBolg from '../../assets/titulos/je-blogs.webp';

const BlogDos = () => {

    const scrollToSection = (inicio) => {
        window.location.hash = "#" + inicio;
    };

    useEffect(() => {
        scrollToSection("inicio")
    }, []);

    return (
        <>
            <HeaderBlog
                banner="banner-img-blog-dos"
                titulo={textos.seccionBlogs.blogDos.titulo}
            />
            <div className="container-fluid pt-5" id="niveles">
                <div className="container">
                    <div className="text-center pb-2">
                        <h1 className="mb-4 text-tomate-outline">{textos.seccionBlogs.blogDos.titulo}</h1>
                    </div>
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12">
                            <p className='texto-justificado'>
                                El compromiso activo entre padres e hijos es la clave para construir relaciones fuertes y duraderas. En este blog, exploraremos cómo los padres pueden participar de manera consciente en la vida de sus hijos, fomentando un ambiente de amor, apoyo y crecimiento mutuo.
                            </p>
                            <strong className="text-green-oscuro">1. Tiempo de Calidad:</strong>
                            <p className='texto-justificado'>
                                Dedicar tiempo exclusivo a actividades compartidas fortalece los lazos emocionales. Ya sea leyendo juntos, cocinando o disfrutando de juegos, el tiempo de calidad crea recuerdos significativos.
                            </p>
                            <strong className="text-green-oscuro">2. Comunicación Abierta:</strong>
                            <p className='texto-justificado'>
                                Fomentar un ambiente donde la comunicación fluya libremente es esencial. Escucha activa, haz preguntas y comparte tus propias experiencias. La apertura crea un espacio donde los niños se sienten cómodos expresando sus pensamientos y emociones.
                            </p>
                            <strong className="text-green-oscuro">3. Participación en Actividades Escolares:</strong>
                            <p className='texto-justificado'>
                                Involucrarse en la educación de tus hijos demuestra interés y apoyo. Asiste a reuniones de padres y maestros, participa en eventos escolares y colabora con los maestros para crear un entorno educativo sólido.
                            </p>
                            <strong className="text-green-oscuro">4. Modelado de Comportamiento:</strong>
                            <p className='texto-justificado'>
                                Los niños aprenden observando. Ser un modelo a seguir positivo, mostrando valores como la empatía, la paciencia y la resiliencia, influye positivamente en su desarrollo emocional y social.
                            </p>
                            <strong className="text-green-oscuro">5. Establecer Tradiciones Familiares:</strong>
                            <p className='texto-justificado'>
                                Las tradiciones crean un sentido de pertenencia. Ya sea celebrando festividades, creando rituales diarios o compartiendo historias familiares, establecer tradiciones fortalece los lazos familiares.
                            </p>
                            <strong className="text-green-oscuro">6. Apoyo en Intereses y Metas:</strong>
                            <p className='texto-justificado'>
                                Apoyar los intereses y metas individuales de tus hijos les brinda confianza y motivación. Sea un apoyo activo en sus pasiones, ya sean académicas, artísticas o deportivas.
                            </p>
                            <p className='texto-justificado'>
                                El compromiso activo entre padres e hijos no solo construye relaciones sólidas, sino que también nutre el crecimiento integral de los niños. Cada interacción, cada conversación y cada momento compartido contribuyen a un lazo único y especial. ¡Haz del compromiso una parte activa de tu viaje familiar!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="text-center pb-2">
                        <img src={tituloBolg} className='titulos' alt="JE Blogs" />
                        {/* <h1 className="mb-4 text-tomate-outline">Artículos</h1> */}
                    </div>
                    <div className="row pb-3 d-flex justify-content-center">
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogDos.titulo}
                            img={bolg1}
                            descripcion={textos.seccionBlogs.blogDos.descipcionCorta}
                            pathBlog='/blog_uno'
                        />
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogTres.titulo}
                            img={bolg3}
                            descripcion={textos.seccionBlogs.blogTres.descipcionCorta}
                            pathBlog='/blog_tres'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDos
