import { useEffect } from 'react';
import BannerAnimado from '../Components/BannerAnimado';
import Docentes from '../scanes/Docentes';
import PropuestaValor from '../scanes/PropuestaValor';
import Valores from '../scanes/Valores';
/* import tituloValores from '../assets/titulos/nuestros-valores.webp'; */
import Aos from 'aos';

const About = () => {


  const scrollToSection = (inicio) => {
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
    scrollToSection("inicio")
  }, []);


  return (
    <>
      <BannerAnimado
        tittle="Sobre Nosotros"
      />
      <PropuestaValor />

      <section className='container-fluid mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='container-mision shadow m-2' data-aos="fade-right">
                <div className="text-center pt-5 mb-3" >
                  {/* <img src={tituloValores} className='titulos-fil' alt="Nuestras profes" data-aos="fade-right"/> */}
                  <h1 className='mt-3 text-green-outline' data-aos="fade-down">Misión</h1>
                </div>
                <div className='container texto-justificado' data-aos="fade-up">
                  Somos una Cooperativa de Servicios Educacionales que brindamos una educación inclusiva de
                  calidad con calidez, basada en valores y principios cooperativos, dirigida a niñas, niños y sus
                  familias, convencidos en que podemos potenciar su desarrollo integral, que aporta a la
                  construcción de una sociedad más justa y equitativa.
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='container-vision shadow m-2' data-aos="fade-down-right">
                <div className="text-center pt-5 mb-3" >
                  {/*   <img src={tituloValores} className='titulos-fil' alt="Nuestras profes" data-aos="fade-right"/> */}
                  <h1 className='mt-3 text-green-outline' data-aos="fade-down">Visión</h1>
                </div>
                <div className='container texto-justificado' data-aos="fade-up">
                  Al 2025 Jardín Esperanza es una cooperativa de educación innovadora en búsqueda constante
                  de la calidad, reconocida a nivel local, basada en valores y principios cooperativos.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Valores />
      <Docentes />
    </>
  )
}

export default About