import React from 'react'

const HeaderBlog = ({ banner }) => {
    return (
        <div className={`container-fluid p-0 d-flex align-items-center justify-content-center ${banner}`} id='inicio'>
            <div className="d-flex flex-column align-items-center justify-content-center p-0 m-0">
               {/*  <h3 className="p-4 text-tomate-outline">{titulo}</h3> */}
               {/*  <div className="d-inline-flex text-white">
                    <p className="m-0">Gerencia</p>
                    <p className="m-0 px-2">/</p>
                    <p className="m-0">Jardin Esperanza</p>
                    <p className="m-0 px-2">/</p>
                    <p className="m-0">Noviembre 2024</p>
                </div> */}
            </div>
        </div>
    )
}

export default HeaderBlog