import HeaderBlog from 'Components/HeaderBlog'
import React, { useEffect } from 'react'
import textos from '../../Data/textos.json'
import CardRecomendaciones from 'Components/CardRecomendaciones'
import bolg2 from '../../assets/compromiso-activo-blog-2.webp';
import bolg3 from '../../assets/aprendizaje-divertido-blog-3.webp';
import tituloBolg from '../../assets/titulos/je-blogs.webp';

const BlogUno = () => {
    const scrollToSection = (inicio) => {
        window.location.hash = "#" + inicio;
    };

    useEffect(() => {
        scrollToSection("inicio")
    }, []);
    return (
        <>
            <HeaderBlog
                banner="banner-img-blog-uno"
            />
            <div className="container-fluid pt-5" id="niveles">
                <div className="container">
                    <div className="text-center pb-2">
                        <h1 className="mb-4 text-tomate-outline">{textos.seccionBlogs.blogUno.titulo}</h1>
                    </div>
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12">
                            <p className='texto-justificado'>
                                El crecimiento de los niños pequeños es un viaje fascinante lleno de descubrimientos y aprendizajes. En este blog, exploraremos cómo los padres y cuidadores pueden contribuir al desarrollo seguro de sus pequeños exploradores, estableciendo las bases para un futuro sólido.
                            </p>
                            <strong className="text-green-oscuro">1. Ambiente Físico Seguro:</strong>
                            <p className='texto-justificado'>
                                Crear un hogar seguro implica minimizar riesgos físicos. Asegúrate de que el entorno sea libre de objetos peligrosos, con esquinas redondeadas y enchufes cubiertos. Proporciona juguetes adecuados a su edad para promover un juego seguro.
                            </p>
                            <strong className="text-green-oscuro">2. Vínculos Afectivos:</strong>
                            <p className='texto-justificado'>
                                El amor y la conexión emocional son cruciales para un desarrollo seguro. Establecer fuertes vínculos con tus hijos crea un ambiente emocionalmente seguro donde se sienten apoyados para explorar el mundo que los rodea.
                            </p>
                            <strong className="text-green-oscuro">3. Estimulación Adecuada:</strong>
                            <p className='texto-justificado'>
                                Ofrece experiencias variadas y enriquecedoras que fomenten el desarrollo cognitivo y motor. Juegos, lecturas y actividades al aire libre contribuyen al crecimiento saludable y seguro.
                            </p>
                            <strong className="text-green-oscuro">4. Comunicación y Observación:</strong>
                            <p className='texto-justificado'>
                                Mantén una comunicación abierta con tus hijos, animándolos a expresar sus emociones. Observa su comportamiento para entender sus necesidades y preocupaciones, promoviendo así un ambiente donde se sientan escuchados y comprendidos.
                            </p>
                            <strong className="text-green-oscuro">5. Establecer Rutinas:</strong>
                            <p className='texto-justificado'>
                                Las rutinas brindan seguridad y previsibilidad. Establecer horarios para comidas, sueño y actividades diarias ayuda a los niños a sentirse seguros y a comprender el mundo que los rodea.
                            </p>
                            <p className='texto-justificado'>
                                En el viaje del desarrollo seguro de los niños, cada interacción y ambiente contribuyen a la construcción de cimientos sólidos. Al adoptar estas prácticas, estarás cultivando un entorno donde tus pequeños puedan crecer con confianza y curiosidad. ¡Haz de cada momento una oportunidad para fortalecer su futuro!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="text-center pb-2">
                        <img src={tituloBolg} className='titulos' alt="JE Blogs" />
                        {/* <h1 className="mb-4 text-tomate-outline">Artículos</h1> */}
                    </div>
                    <div className="row pb-3 d-flex justify-content-center">
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogDos.titulo}
                            img={bolg2}
                            descripcion={textos.seccionBlogs.blogDos.descipcionCorta}
                            pathBlog='/blog_dos'
                        />
                        <CardRecomendaciones
                            titulo={textos.seccionBlogs.blogTres.titulo}
                            img={bolg3}
                            descripcion={textos.seccionBlogs.blogTres.descipcionCorta}
                            pathBlog='/blog_tres'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogUno
