import React from 'react'
import { Link } from 'react-router-dom'
import Animacion from './Animacion'

const BannerAnimado = ({ tittle, size = 'display-3' }) => {

    return (
        <>
            <div className="parallax-container-1  container-fluid bg-celeste" id='inicio'>
                <div className="d-flex flex-column align-items-center justify-content-center banner-heigth" >
                    <h2 className={`${size} text-white text-green-outline text-move-up-down`}>{tittle}</h2>
                    <div className="d-inline-flex text-white">
                        <p className="m-0"><Link className="text-white" to="/">Inicio</Link></p>
                        <p className="m-0 px-2">/</p>
                        <p className="m-0">{tittle}</p>
                    </div>
                </div>
            </div>
            <Animacion />
        </>
    )
}

export default BannerAnimado
