import React, { useEffect } from 'react';
import textos from '../Data/textos.json';
import { BsWhatsapp } from "react-icons/bs";
import Aos from 'aos'

const CardNivelDetalle = ({ imgTitulo, descripcion, edad, horario, img, direccionImg }) => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="row mt-5 mb-5">
            {(direccionImg === "derecha")
                ?
                <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <img className="img-fluid rounded mb-lg-0" src={img} width="300" alt="" data-aos="fade-down"/>
                </div>
                :
                ""
            }
            <div className="col-lg-7">
                <div className="text-center pb-2" >
                    <img src={imgTitulo} className='titulos' alt="Nivel" data-aos="fade-up"/>
                    {/*  <h2 className="mb-4 text-tomate-outline">{titulo}</h2> */}
                </div>
                <p className='texto-justificado' data-aos="fade-right">
                    {descripcion}
                </p>
                <div className="card-footer py-4 px-5" data-aos="fade-up">
                    <div className="row border-bottom">
                        <div className="col-6 py-1 text-right border-right">
                            <strong>Edad</strong>
                        </div>
                        <div className="col-6 py-1">{edad}</div>
                    </div>
                    <div className="row border-bottom">
                        <div className="col-6 py-1 text-right border-right">
                            <strong>Horario</strong>
                        </div>
                        <div className="col-6 py-1">{horario}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center" data-aos="fade-right">
                    <a href={`https://api.whatsapp.com/send?phone=${textos.datosJE.whatsapp}`} target="_blank" rel="noreferrer" title="Solicita asesoría" className="btn btn-secondary d-flex">Solicita más información aquí<BsWhatsapp size={20} className='ml-2 mt-05' /></a>
                </div>
            </div>
            {(direccionImg === "izquierda")
                ?
                <div className="col-lg-4 d-flex align-items-center justify-content-center">
                    <img className="img-fluid rounded mb-lg-0" src={img} width="300" alt="" data-aos="fade-down"/>
                </div>
                :
                ""
            }
        </div>
    )
}

export default CardNivelDetalle
