

const CardGallery = ({ img, tipo }) => {
    return (
        <div className={`scroll-up col-lg-4 col-md-6 mb-4 portfolio-item ${tipo}`}>
            <div className="position-relative overflow-hidden mb-2">
                <img className="img-fluid img-galeria w-90" src={img} alt={img} />
            </div>
        </div>
    )
}

export default CardGallery
