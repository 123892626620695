import React, { useEffect, useState } from 'react'
import BannerAnimado from '../Components/BannerAnimado'
/* import Isotope from 'isotope-layout'; */
import CardGallery from 'Components/CardGallery'
import foto1 from '../assets/galeria/1.png';
import foto2 from '../assets/galeria/2.png';
import foto3 from '../assets/galeria/3.png';
import foto4 from '../assets/galeria/4.png';
import foto5 from '../assets/galeria/5.png';
/* import foto6 from '../assets/galeria/6.png'; */
import foto7 from '../assets/galeria/7.png';
import foto8 from '../assets/galeria/8.png';
import foto9 from '../assets/galeria/9.png';
import foto10 from '../assets/galeria/10.png';
import foto11 from '../assets/galeria/11.png';
import foto12 from '../assets/galeria/12.png';
/* import foto13 from '../assets/galeria/13.png'; */
import foto14 from '../assets/galeria/14.png';
import foto15 from '../assets/galeria/15.png';
/* import foto16 from '../assets/galeria/16.png';
import foto17 from '../assets/galeria/17.png';
import foto18 from '../assets/galeria/18.png'; */
import foto19 from '../assets/galeria/19.png';
import foto20 from '../assets/galeria/20.png';
import foto21 from '../assets/galeria/21.png';
/* import foto22 from '../assets/galeria/22.png';
import foto23 from '../assets/galeria/23.png';
import foto24 from '../assets/galeria/24.png';
import foto25 from '../assets/galeria/25.png'; */
import foto26 from '../assets/galeria/26.png';
/* import foto27 from '../assets/galeria/27.png'; */

const Gallery = () => {

  const [galeria, setGaleria] = useState('academicas');

  const scrollToSection = (inicio) => {
    console.log("EN SCROLL")
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    scrollToSection("inicio")
  }, []);

  return (
    <>
      <BannerAnimado tittle="Galería" />

      <div className="container-fluid pt-5 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <ul className="list-inline mb-4">
                <li className={`btn btn-outline-primary m-1 ${(galeria === 'academicas') ? 'active' : ''}`} onClick={() => setGaleria('academicas')}>
                  Académicas
                </li>
                <li className={`btn btn-outline-primary m-1 ${(galeria === 'socioCulturales') ? 'active' : ''}`} onClick={() => setGaleria('socioCulturales')}>
                  Socio culturales
                </li>
                <li className={`btn btn-outline-primary m-1 ${(galeria === 'extraCurriculares') ? 'active' : ''}`} onClick={() => setGaleria('extraCurriculares')}>
                  Extra curriculares
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            {
              (galeria === 'academicas') ?
                <>
                  <CardGallery img={foto7} tipo="colonia" />
                  <CardGallery img={foto8} tipo="colonia" />
                  <CardGallery img={foto9} tipo="colonia" />
                  <CardGallery img={foto10} tipo="colonia" />
                  <CardGallery img={foto19} tipo="colonia" />
                  <CardGallery img={foto20} tipo="colonia" />
                  <CardGallery img={foto21} tipo="colonia" />
                </>
                :
                (galeria === 'socioCulturales') ?
                  <>
                    <CardGallery img={foto1} tipo="clausura" />
                    <CardGallery img={foto2} tipo="clausura" />
                    <CardGallery img={foto3} tipo="clausura" />
                    <CardGallery img={foto4} tipo="clausura" />
                    <CardGallery img={foto5} tipo="clausura" />
                    <CardGallery img={foto26} tipo="clausura" />
                  </>
                  :
                  (galeria === 'extraCurriculares') ?
                    <>
                      <CardGallery img={foto11} tipo="navidad" />
                      <CardGallery img={foto12} tipo="navidad" />
                      <CardGallery img={foto14} tipo="navidad" />
                      <CardGallery img={foto15} tipo="navidad" />
                    </>
                    :
                    ''
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default Gallery
