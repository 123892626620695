import React from 'react'
import logoWhatsApp from '../assets/logos/whatsapp.webp';
import textos from '../Data/textos.json'

const WhatsApp = () => {

    return (
        <div className="whatsapp-icon">
            <a href={`https://api.whatsapp.com/send?phone=${textos.datosJE.whatsapp}`} target="_blank" rel="noreferrer" title="Chatea con nosotros en WhatsApp">
                <img className='img-fluid' src={logoWhatsApp} alt="WhatsApp Icon"></img>
            </a>
        </div>
    )
}

export default WhatsApp