import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Aos from 'aos'

const CardRecomendaciones = ({ titulo, img, descripcion, pathBlog, dataAos }) => {

    const [currentMonthYear, setCurrentMonthYear] = useState('');

    useEffect(() => {
        const getCurrentMonthYear = () => {
            const currentDate = new Date();
            const month = currentDate.toLocaleString('default', { month: 'long' });
            const year = currentDate.getFullYear();
            const formattedMonthYear = `${month} ${year}`;
            setCurrentMonthYear(formattedMonthYear);
        };

        getCurrentMonthYear();

    }, []);

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="col-lg-4 mb-4">
            <div className="card border-0 mb-2 bg-articulo" data-aos={dataAos} >
                <img className="card-img-top mb-2" src={img} alt="Blog"></img>
                <div className="card-body text-center p-4">
                    <h5 className="">{titulo}</h5>
                    <div className="d-flex justify-content-center mb-3">
                        <small className="mr-3"><i className="fa fa-user text-primary"></i> Gerencia</small>
                        <small className="mr-3"><i className="fa fa-comments text-primary"></i>{currentMonthYear}</small>
                    </div>
                    <p className='texto-justificado'>
                        {descripcion}
                    </p>
                    <Link to={pathBlog} className="btn btn-secondary px-4 mx-auto my-2">Leer más</Link>
                </div>
            </div>
        </div>
    )
}

export default CardRecomendaciones
