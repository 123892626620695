import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import jeLogo from '../assets/logos/je-logo-full-color.png';
import textos from '../Data/textos.json';
import Aos from 'aos'

const Header = () => {
    const location = useLocation();

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (

        <nav className="container-fluid shadow nav-border-top bg-light">
            <div className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5">
                <div className='toger'>
                    <Link to="/" className="container-logo p-1">
                        <img src={jeLogo} alt="Jardín Esperanza" className="img-fluid logo-width"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <div className="navbar-nav mx-0 py-1">
                        <Link to="/" className={location.pathname === '/' ? 'nav-item nav-link active' : 'nav-item nav-link'}>Inicio</Link>
                        {/* <Link to="/" className={location.pathname === '/transparencia' ? 'nav-item nav-link active ' : 'nav-item nav-link'}>Transparencia</Link> */}
                        <Link to="/nosotros" className={location.pathname === '/nosotros' ? 'nav-item nav-link active ' : 'nav-item nav-link'}>Nuestro centro</Link>
                        <Link to="/servicios" className={location.pathname === '/servicios' ? 'nav-item nav-link active ' : 'nav-item nav-link'}>Nuestros servicios</Link>
                        <Link to="/galeria" className={location.pathname === '/galeria' ? 'nav-item nav-link active ' : 'nav-item nav-link'}>Galería de recuerdos</Link>
                        <div className='container-bt-contactanos'>
                            <a href={`https://api.whatsapp.com/send?phone=${textos.datosJE.whatsapp}`} target="_blank" rel="noreferrer" className="btn color-changing-button px-4">Contáctanos</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;