import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Aos from 'aos'

const CardNivel = ({ description, img }) => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);


    return (
        <div className="col-lg-4 mb-5" data-aos="fade-right">
            <div className="card-body pb-2">
                <img className="card-img-top mb-2 img-fluid" src={img} alt=""></img>
                <div className="card- text-center">
                    <p className="card-text" data-aos="fade-up">
                        {description}
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4" data-aos="fade-up">
                    <Link to="/servicios" className="btn btn-secondary">Conoce más niveles aquí</Link>
                </div>
            </div>
        </div>
    )
}

export default CardNivel