import React, { useEffect } from 'react'
import BannerAnimado from '../Components/BannerAnimado'
import Niveles from '../scanes/Niveles'

const Contact = () => {

  const scrollToSection = (inicio) => {
    console.log("EN SCROLL")
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    scrollToSection("inicio")
  }, []);

  return (
    <>
      <BannerAnimado
        tittle="Reserva un cupo"
      />
      <Niveles/>
      <Niveles/>
      <Niveles/>
    </>
  )
}

export default Contact
