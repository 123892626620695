import React, { useEffect } from 'react'
import Aos from 'aos'

const CardActividad = ({ titulo, descripcion, imagen }) => {
    
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);


    return (
        <div className="col-lg-6 col-md-6 mb-3">
            <div className="d-flex bg-actividad p-30">
                <img className="img-fluid card-img-top img-servicio-adicional" src={imagen} alt={titulo} data-aos="fade-up"/>
                <div className="pl-4" data-aos="fade-right">
                    <h4 className='mt-3 text-actividad-outline'>{titulo}</h4>
                    <p className='texto-justificado'>
                        {descripcion}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CardActividad